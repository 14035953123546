import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cn from "classnames";
import { useState } from "react";
import { Route, Routes } from "react-router-dom";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import Home from "./Home";
import Thanks from "./Thanks";
import freebird from "./assets/header.jpg";
import { buttonClass } from "./contants";

const App = () => {
  const [displayForm, setDisplayForm] = useState(false);

  const handleSetForm = (bool) => {
    if (bool === displayForm) return;
    setDisplayForm(bool);
  };
  const mailTo = (e) => {
    e.preventDefault();
    window.open("mailto:americafirst@americansunited.co?subject=United We Stand!");
  };

  return (
    <div className="w-full h-full bg-main bg-left-bottom bg-no-repeat overflow-hidden">
      <div className="md:grid md:grid-cols-12 h-full">
        <div className={cn("h-[130px] flex items-center col-span-12 md:col-span-6 md:p-10 md:items-start")}>
          <img src={freebird} alt="free bird" className="md:hidden" />
          <div className="ml-5 md:ml-0">
            <p className="text-2xl md:text-4xl font-bold mb-1">Americans United</p>
            <p className="text-2xl md:text-4xl font-bold">Against the New World Order</p>
          </div>
        </div>
        <div className="h-full bg-midnight col-span-12 md:col-span-6">
          <div className="p-10 justify-end hidden md:flex">
            <button className={cn(buttonClass, "py-2")} onClick={() => handleSetForm(true)}>
              Donate Now
            </button>
            <button className={cn(buttonClass, "ml-4 py-2")} onClick={mailTo}>
              <FontAwesomeIcon icon={faEnvelope} color="white" size="lg" />
            </button>
          </div>
          <Routes>
            <Route index element={<Home displayForm={displayForm} handleClick={setDisplayForm} />} />
            <Route path="thanks" element={<Thanks />} />
          </Routes>
        </div>
      </div>

      {/* <div className="sm:hidden">
        <div className="p-10 flex justify-center">main</div>
      </div> */}
    </div>
  );
};

export default App;
