import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cn from "classnames";
import React from "react";
import { buttonClass } from "../contants";

const Home = ({ displayForm, handleClick }) => {
  return (
    <div className={cn("flex h-[calc(100%-130px)] justify-center items-center")}>
      {displayForm ? (
        <div className="flex flex-col items-end justify-center -mt-10">
          <div className="flex justify-end w-full bg-white">
            <FontAwesomeIcon icon={faClose} color="#8a280f" size="lg" className="m-4 mb-0" onClick={() => handleClick(false)} />
          </div>
          <iframe id="donation-iframe" src="https://quickclick.com/r/wb88y" title="AUAMATNWO" width="516" height="550" style={{ border: "none" }}></iframe>
        </div>
      ) : (
        <div className="flex flex-col items-center justify-between md:justify-normal md:items-center h-full md:h-[600px] w-full md:w-4/5">
          <p className="flex w-full h-full px-5 md:px-0 md:h-fit items-center max-w-[516px] text-[28px] text-white font-light leading-[3rem] md:leading-[4rem]">
            Sharing information to help patriotic American citizens from all racial, ethnic, and economic backgrounds protect, preserve, & defend the sovereignty of our American
            Constitutional Republic.
          </p>
          <button className={cn(buttonClass, "text-2xl w-full md:w-4/5 md:py-3 md:mt-12 rounded-none py-8 md:max-w-[430px]")} onClick={() => handleClick(true)}>
            Donate Now
          </button>
        </div>
      )}
    </div>
  );
};

export default Home;
