import React from "react";

const Thanks = () => {
  return (
    <div className="flex h-[calc(100%-130px)] justify-center items-center text-white">
      <div className="flex flex-col items-center text-lg px-3">
        <p className="text-5xl font-light mb-4">Thank You!</p>
        <p className="text-center">Your contribution is greatly appreciated.</p>
        <p className="text-center">
          Because of you we'll be able to continue the fight to restore traditional American values and maintain individual lawful sovereignty for "We the People".
        </p>
        <p className="mt-4 text-center">
          If you enjoy our content, please continue viewing our{" "}
          <a href="https://www.bitchute.com/channel/2kft2PIkOcKe/" target="blank" rel="noopener noreferrer" className="font-semibold text-dodger">
            BitChute Channel
          </a>
          , and don't forget to invite a friend!
        </p>
      </div>
    </div>
  );
};

export default Thanks;
